import React, { useState } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import BackgroundImage from "gatsby-background-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import HeroSplit from "../components/Hero/HeroSplit";
import Services from "../components/Repeating/Services";
import TestimonialBackgroundImage from "../components/Testimonial/TestimonialBackgroundImage";
import WhyUs from "../components/Repeating/WhyUs";
import CTABackgroundImage from "../components/CTA/CTABackgroundImage";
import ModalGallery from "../components/Modal/ModalGallery";
import IndustriesSidebar from "../components/Repeating/IndustriesSidebar";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
   const [slideIndex, setSlideIndex] = useState(0);

   const companiesWeServeImages = [
      data.companiesWeBuiltDesktop.childImageSharp.fixed,
      {
         ...data.companiesWeBuiltMobile.childImageSharp.fixed,
         media: `(max-width: 767px)`,
      },
   ];

   return (
      <Layout headerStyle="overlap" headerLinkColor="white" headerHasBorder={false}>
         <SearchEngineOptimization
            title="Themed Parts Manufacturer | Painted Rhino"
            description="When you need quality themed product production from a trusted manufacturer, choose Painted Rhino. Learn more here and see what we can do!"
            // openGraphImage={data.openGraphImage.publicURL}
            // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />

         <HeroSplit
            hasContainer={false}
            backgroundImageWrapperWidth="1/2"
            textWrapperWidth="1/2"
            backgroundImages={data.heroDesktop.childImageSharp.fluid}
            backgroundImageSide="right"
            backgroundSizeDesktop="cover"
            backgroundSizeMobile="cover"
            backgroundPositionDesktop="50% 50%"
            backgroundPositionMobile="50% 50%"
            minHeightDesktop="704px"
            minHeightMobile="330px"
         >
            <h1>Themed Products Manufacturing</h1>
            <p>
               When you need quality themed product production from a trusted manufacturer, choose Painted Rhino. We’re a family-owned business with
               extensive industry expertise and a strong focus on innovation and creative solutions. You bring us your design, and we’ll manufacture
               it.
            </p>
            <ButtonSolid as="button" data="modal-contact" text="Get a Quote" className="w-full md:w-auto" />
         </HeroSplit>

         <section className="pb-18 md:pb-36">
            <div className="container relative">
               <div className="grid gap-y-12 md:grid-cols-12 md:gap-x-10 lg:gap-x-20">
                  <div className="md:col-span-8 md:col-start-1">
                     <div className="mb-12 md:mb-16">
                        <h2>You Dream It, We’ll Make It</h2>
                        <p>
                           From theme park design to custom signage and packaging for restaurants/retail to specialty props that create movie magic,
                           we help bring the most creative and innovative ideas to life. Nothing is too grand—for example, we once produced a
                           life-sized Gingerbread house for a hotel’s rooftop bar. Our team can also manufacture a prototype and even aid in the
                           design so that you can test a concept.
                        </p>
                     </div>

                     <h3>What We Can Produce</h3>
                     <p>
                        Our team has decades of experience in <a href="https://www.paintedrhino.com/fiberglass-composite-parts-manufacturing/" className="text-[#006ee8]">composite</a>, <a href="https://www.paintedrhino.com/vacuum-forming-thermoforming-company/" className="text-[#006ee8]">vacuum forming</a>, and <a href="https://www.paintedrhino.com/injection-urethane-rotocasting/" className="text-[#006ee8]">urethane plastic manufacturing</a>, and we offer a broad
                        scope of top-quality <a href="https://www.paintedrhino.com/special-effects-props/" className="text-[#006ee8]">themed item production</a> that includes, but is not limited to:
                     </p>

                     <ul className="styled-list">
                        <li>Statues</li>
                        <li>Ride cars</li>
                        <li>Faux backgrounds</li>
                        <li>Themed point-of-purchase displays</li>
                        <li>Static props</li>
                        <li>Models</li>
                        <li>Custom lighting</li>
                        <li>Signage</li>
                        <li>Fastpass kiosks</li>
                        <li>Photo ops</li>
                        <li>Parade float facades</li>
                        <li>In-ride theming</li>
                        <li>Custom faux painting</li>
                        <li>3D wall reliefs</li>
                        <li>Custom seating</li>
                        <li>Restaurant booths</li>
                        <li>Pop displays</li>
                     </ul>
                  </div>
                  <div className="md:col-end-13 lg:col-span-4">
                     <IndustriesSidebar />
                  </div>
               </div>
            </div>
         </section>

         <section className="pb-20 md:pb-32">
            <div className="container">
               <div className="grid gap-2 md:grid-cols-2 md:gap-4">
                  <div className="grid grid-cols-10 grid-rows-2 gap-2 md:gap-4">
                     <div className="col-span-6 col-start-1 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(0)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="h-full w-full"
                              fluid={data.galleryThumbnail1.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-span-4 col-end-11 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(1)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="h-full w-full"
                              fluid={data.galleryThumbnail2.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-span-4 col-start-1 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(4)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="h-full w-full"
                              fluid={data.galleryThumbnail5.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-span-6 col-end-11 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(3)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="h-full w-full"
                              fluid={data.galleryThumbnail4.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                  </div>
                  <div className="grid grid-flow-col grid-cols-10 grid-rows-2 gap-2 md:gap-4">
                     <div className="col-span-4 row-span-2">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(2)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="h-full w-full"
                              fluid={data.galleryThumbnail3.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-span-6 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(5)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="h-full w-full"
                              fluid={data.galleryThumbnail6.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                     <div className="col-span-6 row-span-1">
                        <button aria-label="Modal trigger" data-modal-open="modal-gallery" onClick={() => setSlideIndex(6)}>
                           <Img
                              fadeIn={false}
                              loading="eager"
                              className="h-full w-full"
                              fluid={data.galleryThumbnail7.childImageSharp.fluid}
                              alt=""
                           />
                        </button>
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <BackgroundImage fixed={companiesWeServeImages} className="w-full pt-20 pb-24 md:pt-32 md:pb-36">
            <div className="container relative">
               <header className="mb-18 md:mb-22 text-center">
                  <p className="mb-0 text-sm font-bold uppercase tracking-wider text-black">Companies We’ve Built For</p>
               </header>

               <div className="mx-auto grid max-w-5xl grid-cols-3 items-center gap-x-8 gap-y-12 md:gap-x-12 md:gap-y-20 lg:grid-cols-5">
                  <div>
                     <Img fadeIn={false} loading="eager" className="mx-auto" fluid={data.clubCar.childImageSharp.fluid} alt="Club Car logo" />
                  </div>
                  <div>
                     <Img
                        fadeIn={false}
                        loading="eager"
                        className="mx-auto"
                        fluid={data.americanMuseumOfNaturalHistory.childImageSharp.fluid}
                        alt="American Museum Of Natural History logo"
                     />
                  </div>
                  <div>
                     <Img fadeIn={false} loading="eager" className="mx-auto" fluid={data.disneyland.childImageSharp.fluid} alt="Disneyland logo" />
                  </div>
                  <div>
                     <Img
                        fadeIn={false}
                        loading="eager"
                        className="mx-auto"
                        fluid={data.ingersollRand.childImageSharp.fluid}
                        alt="Ingersoll Rand logo"
                     />
                  </div>
                  <div>
                     <Img
                        fadeIn={false}
                        loading="eager"
                        className="mx-auto"
                        fluid={data.kennedySpaceCenter.childImageSharp.fluid}
                        alt="Kennedy Space Center logo"
                     />
                  </div>
                  <div>
                     <Img fadeIn={false} loading="eager" className="mx-auto" fluid={data.LAX.childImageSharp.fluid} alt="LAX logo" />
                  </div>
                  <div>
                     <Img fadeIn={false} loading="eager" className="mx-auto" fluid={data.pilot.childImageSharp.fluid} alt="Pilot logo" />
                  </div>
                  <div>
                     <Img fadeIn={false} loading="eager" className="mx-auto" fluid={data.edison.childImageSharp.fluid} alt="Edison logo" />
                  </div>
                  <div>
                     <Img fadeIn={false} loading="eager" className="mx-auto" fluid={data.yamaha.childImageSharp.fluid} alt="Yamaha logo" />
                  </div>
                  <div>
                     <Img fadeIn={false} loading="eager" className="mx-auto" fluid={data.universal.childImageSharp.fluid} alt="Universal logo" />
                  </div>
                  <div>
                     <Img
                        fadeIn={false}
                        loading="eager"
                        className="mx-auto"
                        fluid={data.theCosmopolitan.childImageSharp.fluid}
                        alt="The Cosmopolitan logo"
                     />
                  </div>
                  <div>
                     <Img
                        fadeIn={false}
                        loading="eager"
                        className="mx-auto"
                        fluid={data.cinemaSecrets.childImageSharp.fluid}
                        alt="Cinema Secrets logo"
                     />
                  </div>
                  <div>
                     <Img fadeIn={false} loading="eager" className="mx-auto" fluid={data.GAS.childImageSharp.fluid} alt="GAS logo" />
                  </div>
                  <div>
                     <Img fadeIn={false} loading="eager" className="mx-auto" fluid={data.lampsPlus.childImageSharp.fluid} alt="Lamps Plus logo" />
                  </div>
                  <div>
                     <Img fadeIn={false} loading="eager" className="mx-auto" fluid={data.UCR.childImageSharp.fluid} alt="UCR logo" />
                  </div>
               </div>
            </div>
         </BackgroundImage>

         <WhyUs
            className="pt-20 pb-20 md:pt-28 md:pb-28"
            contentAlignment="md:text-center md:mx-auto"
            headerHeading="The Painted Rhino Difference."
         />

         <TestimonialBackgroundImage />

         <Services
            className="mb-22 pt-8 md:mb-32 md:pt-16"
            headerHeading="Our Manufacturing Processes"
            headerText="At Painted Rhino, you bring us your design and we’ll manufacture it. We’re experts in:"
         />

         <CTABackgroundImage />

         <ModalGallery slideIndex={slideIndex}>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox1.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox2.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox3.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox4.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox5.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox6.childImageSharp.fluid} alt="" />
            </div>
            <div>
               <Img fadeIn={false} loading="eager" fluid={data.galleryLightbox7.childImageSharp.fluid} alt="" />
            </div>
         </ModalGallery>
      </Layout>
   );
};

export default Page;

export const query = graphql`
   {
      heroDesktop: file(relativePath: { eq: "industries/Themed Products/1.0- Hero-Themed Products.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 800) {
               ...GatsbyImageSharpFluid_withWebp
            }
         }
      }
      galleryThumbnail1: file(relativePath: { eq: "industries/Themed Products/Gallery - Thumbnails/Theme-1.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 355) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox1: file(relativePath: { eq: "industries/Themed Products/Gallery - Lightbox/1 (2).jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail2: file(relativePath: { eq: "industries/Themed Products/Gallery - Thumbnails/Theme-2.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 221) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox2: file(relativePath: { eq: "industries/Themed Products/Gallery - Lightbox/2 (2).jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail3: file(relativePath: { eq: "industries/Themed Products/Gallery - Thumbnails/Theme-3.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 222) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox3: file(relativePath: { eq: "industries/Themed Products/Gallery - Lightbox/3 (2).jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail4: file(relativePath: { eq: "industries/Themed Products/Gallery - Thumbnails/Theme-4.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 354) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox4: file(relativePath: { eq: "industries/Themed Products/Gallery - Lightbox/4 (2).jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail5: file(relativePath: { eq: "industries/Themed Products/Gallery - Thumbnails/Theme-5.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 222) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox5: file(relativePath: { eq: "industries/Themed Products/Gallery - Lightbox/5 (2).jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail6: file(relativePath: { eq: "industries/Themed Products/Gallery - Thumbnails/Theme-6.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 354) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox6: file(relativePath: { eq: "industries/Themed Products/Gallery - Lightbox/6 (2).jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryThumbnail7: file(relativePath: { eq: "industries/Themed Products/Gallery - Thumbnails/Theme-7.jpg" }) {
         childImageSharp {
            fluid(maxWidth: 354) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      galleryLightbox7: file(relativePath: { eq: "industries/Themed Products/Gallery - Lightbox/7 (2).jpg" }) {
         childImageSharp {
            fluid(maxHeight: 800) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      companiesWeBuiltDesktop: file(relativePath: { eq: "home/Companies-We-Built-For-BG-Desktop.jpg" }) {
         childImageSharp {
            fixed(width: 2880, height: 1432) {
               ...GatsbyImageSharpFixed_withWebp
            }
         }
      }
      companiesWeBuiltMobile: file(relativePath: { eq: "home/Companies-We-Built-For-BG-Mobile.jpg" }) {
         childImageSharp {
            fixed(width: 750, height: 1270) {
               ...GatsbyImageSharpFixed_withWebp
            }
         }
      }
      clubCar: file(relativePath: { eq: "home/Club-Car.png" }) {
         childImageSharp {
            fluid(maxWidth: 117) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      americanMuseumOfNaturalHistory: file(relativePath: { eq: "home/American-Museum-of-Natural-History.png" }) {
         childImageSharp {
            fluid(maxWidth: 154) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      disneyland: file(relativePath: { eq: "home/Disneyland.png" }) {
         childImageSharp {
            fluid(maxWidth: 139) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      ingersollRand: file(relativePath: { eq: "home/Ingersoll-Rand.png" }) {
         childImageSharp {
            fluid(maxWidth: 152) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      kennedySpaceCenter: file(relativePath: { eq: "home/Kennedy-Space-Center.png" }) {
         childImageSharp {
            fluid(maxWidth: 130) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      LAX: file(relativePath: { eq: "home/LAX.png" }) {
         childImageSharp {
            fluid(maxWidth: 83) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      pilot: file(relativePath: { eq: "home/Pilot.png" }) {
         childImageSharp {
            fluid(maxWidth: 149) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      edison: file(relativePath: { eq: "home/Edison.png" }) {
         childImageSharp {
            fluid(maxWidth: 139) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      yamaha: file(relativePath: { eq: "home/Yamaha.png" }) {
         childImageSharp {
            fluid(maxWidth: 160) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      universal: file(relativePath: { eq: "home/Universal.png" }) {
         childImageSharp {
            fluid(maxWidth: 108) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      theCosmopolitan: file(relativePath: { eq: "home/The-Cosmopolitan.png" }) {
         childImageSharp {
            fluid(maxWidth: 136) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      cinemaSecrets: file(relativePath: { eq: "home/cinema-secrets.png" }) {
         childImageSharp {
            fluid(maxWidth: 101) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      GAS: file(relativePath: { eq: "home/GAS-Galpin-Auto-Spots.png" }) {
         childImageSharp {
            fluid(maxWidth: 76) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      lampsPlus: file(relativePath: { eq: "home/Lamps-Plus.png" }) {
         childImageSharp {
            fluid(maxWidth: 146) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
      UCR: file(relativePath: { eq: "home/UC-Riverside.png" }) {
         childImageSharp {
            fluid(maxWidth: 128) {
               ...GatsbyImageSharpFluid_withWebp
               ...GatsbyImageSharpFluidLimitPresentationSize
            }
         }
      }
   }
`;
